import React from 'react';

import Button from '@/atoms/Button';
import Head from '@/components/Head';
import Page from '@/components/Page';
import ModulesLoop from '@/components/ModulesLoop';

export default () => {
  return (
    <>
      <Head title="(404) Page not found" meta={{ robotsIndex: false }} />

      <Page background="white">
        <ModulesLoop
          contentModules={{
            modules: [
              {
                id: '404',
                type: 'moduleCopy',
                label: '404',
                text:
                  'Page not found — Please use the navigation bar to access the content you are looking for or visit the front page:',
                children: (
                  <Button as="a" href="/" mt={[1.75, 1.75, 2.25]}>
                    Front page →
                  </Button>
                ),
              },
            ],
          }}
          background="white"
        />
      </Page>
    </>
  );
};
